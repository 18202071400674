import * as React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Header from "./Header"
import "../styles/style.css"
import Footer from "./Footer"
import Navbar from "./Navbar"
import bg from "../images/background.jpg"

export default function Layout({ children }) {
  return (
    <>
      <div>
        <img src={bg} className="background" alt="" />
        <Header />
        <Navbar />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}
