import * as React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSubOpen, setIsSubOpen] = useState({})
  const data = useStaticQuery(graphql`
    query NavbarMainButtons {
      allGraphCmsA3EnlacesMenudeNavegacion {
        edges {
          node {
            id
            textoDelEnlacePrincipal
            urlDelEnlacePrincipal
            a4SubEnlacesMenusdeNavegacion {
              textoDelEnlaceSecundario
              urlDelEnlaceSecundario
            }
          }
        }
      }
    }
  `)

  const SetOpen = () => {
    setIsOpen(!isOpen)
  }

  const SetSubOpen = (id) => {
    console.log("id", id);
    let toggle = {}
    toggle[id] = !isSubOpen[id]
    setIsSubOpen(toggle)
  }

  let NavbarMainButtons = data.allGraphCmsA3EnlacesMenudeNavegacion.edges

  return (
    <nav className="navbar-section">
      <div className="navbar-content">
        <div className="navbar-grid">
          <Link key="inicio" to="/">
            <StaticImage
              placeholder="blurred"
              className="navbar-logo"
              src="../images/cenhies.png"
              alt=""
            />
          </Link>
          <div className="navbar-links-grid">
            <Link key="indice" className="navbar-menu-link" to="/">
              Inicio
            </Link>
            <Link key="acerca" className="navbar-menu-link" to="/conocenos">
              Conócenos
            </Link>
            {NavbarMainButtons
              ? NavbarMainButtons.map(link => (
                <>
                  {link.node.urlDelEnlacePrincipal &&
                    link.node.a4SubEnlacesMenusdeNavegacion.length === 0 ? (
                    <Link
                      title={link.node.textoDelEnlacePrincipal}
                      className="navbar-menu-link"
                      key={link.node.id}
                      to={`/${link.node.urlDelEnlacePrincipal}`}
                    >
                      {link.node.textoDelEnlacePrincipal}
                    </Link>
                  ) : (
                    <div>
                      <button
                        title={link.textoDelEnlacePrincipal}
                        className="navbar-menu-button"
                      >
                        {link.node.textoDelEnlacePrincipal}
                      </button>
                      <div className="navbar-submenu">
                        <div className="navbar-submenu-div">
                          {link.node.a4SubEnlacesMenusdeNavegacion
                            ? link.node.a4SubEnlacesMenusdeNavegacion.map(
                              sublink => (
                                <Link
                                  title={sublink.textoDelEnlaceSecundario}
                                  className="navbar-menu-sublink"
                                  to={`/${link.node.urlDelEnlacePrincipal}/${sublink.urlDelEnlaceSecundario}`}
                                >
                                  {sublink.textoDelEnlaceSecundario}
                                </Link>
                              )
                            )
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))
              : ""}
            <Link
              key="beneficios"
              className="navbar-menu-link"
              to="/beneficios"
            >
              Beneficios
            </Link>
          </div>
          <div />
          <button className="navbar-burgermenu" onClick={SetOpen}>
            <span className="navbar-fa-bars" />
          </button>
        </div>
      </div>
      <button
        onClick={SetOpen}
        className={
          isOpen ? `navbar-sidebar-modalopen` : `navbar-sidebar-modalhide`
        }
      >
        {""}
      </button>
      <div className={isOpen ? `navbar-sidebar-open` : `navbar-sidebar-hide`}>
        <button className="navbar-sidebar-burgermenu" onClick={SetOpen}>
          <span className="navbar-sidebar-fa-bars" />
        </button>
        <StaticImage
          placeholder="blurred"
          quality={95}
          className="navbar-sidebar-logo"
          src="../images/cenhies.png"
          alt=""
        />
        <div className="navbar-sidebar-grid">
          <Link
            key="index"
            className="navbar-menu-link"
            to="/"
            onClick={SetOpen}
          >
            Inicio
          </Link>
          <Link
            key="about"
            className="navbar-menu-link"
            to="/conocenos"
            onClick={SetOpen}
          >
            Conócenos
          </Link>
          {NavbarMainButtons
            ? NavbarMainButtons.map(link => (
              <>
                {link.node.urlDelEnlacePrincipal &&
                  link.node.a4SubEnlacesMenusdeNavegacion.length === 0 ? (
                  <Link
                    key={link.node.id}
                    title={link.node.textoDelEnlacePrincipal}
                    className="navbar-menu-link"
                    onClick={SetOpen}
                    to={`/${link.node.urlDelEnlacePrincipal}`}
                  >
                    {link.node.textoDelEnlacePrincipal}
                  </Link>
                ) : (
                  <div>
                    <button
                      title={link.node.textoDelEnlacePrincipal}
                      className={`navbar-menu-button${isSubOpen[link.node.textoDelEnlacePrincipal] ? "-hover" : ""}`}
                      onClick={() => { SetSubOpen(link.node.textoDelEnlacePrincipal) }}
                    >
                      {link.node.textoDelEnlacePrincipal}
                    </button>
                    <div className="navbar-submenu">
                      <div className="navbar-submenu-div">
                        {link.node.a4SubEnlacesMenusdeNavegacion
                          ? link.node.a4SubEnlacesMenusdeNavegacion.map(
                            sublink => (
                              <Link
                                title={sublink.textoDelEnlaceSecundario}
                                className="navbar-menu-sublink"
                                to={`/${link.node.urlDelEnlacePrincipal}/${sublink.urlDelEnlaceSecundario}`}
                                onClick={
                                  () => {
                                    setIsSubOpen({})
                                    SetOpen()
                                  }}
                              >
                                {sublink.textoDelEnlaceSecundario}
                              </Link>
                            )
                          )
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))
            : ""}
          <Link
            key="benefits"
            onClick={SetOpen}
            className="navbar-menu-link"
            to="/beneficios"
          >
            Beneficios
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
