// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-de-privacidad-js": () => import("./../../../src/pages/aviso-de-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-js" */),
  "component---src-pages-becas-comunidad-extendida-js": () => import("./../../../src/pages/becas-comunidad-extendida.js" /* webpackChunkName: "component---src-pages-becas-comunidad-extendida-js" */),
  "component---src-pages-becas-comunidad-js": () => import("./../../../src/pages/becas-comunidad.js" /* webpackChunkName: "component---src-pages-becas-comunidad-js" */),
  "component---src-pages-beneficios-js": () => import("./../../../src/pages/beneficios.js" /* webpackChunkName: "component---src-pages-beneficios-js" */),
  "component---src-pages-conocenos-js": () => import("./../../../src/pages/conocenos.js" /* webpackChunkName: "component---src-pages-conocenos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-normatividad-js": () => import("./../../../src/pages/normatividad.js" /* webpackChunkName: "component---src-pages-normatividad-js" */),
  "component---src-pages-normatividad-secundaria-js": () => import("./../../../src/pages/normatividad-secundaria.js" /* webpackChunkName: "component---src-pages-normatividad-secundaria-js" */),
  "component---src-pages-preparatoria-js": () => import("./../../../src/pages/preparatoria.js" /* webpackChunkName: "component---src-pages-preparatoria-js" */),
  "component---src-pages-secundaria-js": () => import("./../../../src/pages/secundaria.js" /* webpackChunkName: "component---src-pages-secundaria-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-templates-page-mastery-jsx": () => import("./../../../src/templates/PageMastery.jsx" /* webpackChunkName: "component---src-templates-page-mastery-jsx" */),
  "component---src-templates-page-specialty-jsx": () => import("./../../../src/templates/PageSpecialty.jsx" /* webpackChunkName: "component---src-templates-page-specialty-jsx" */),
  "component---src-templates-page-university-jsx": () => import("./../../../src/templates/PageUniversity.jsx" /* webpackChunkName: "component---src-templates-page-university-jsx" */)
}

