import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="footer-section">
    <div className="footer-content">
      <div className="footer-grid">
        <div>
          <p className="footer-text">¿Cómo llegar?</p>
          <div className="iframemap-section">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3746.789371283383!2d-98.76671528508268!3d20.101089786508172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1a0bb52cfa8bf%3A0x1cdd2da2b5aad5b!2sCENHIES!5e0!3m2!1ses-419!2smx!4v1643148382303!5m2!1ses-419!2smx"
              className="iframemap-map"
              allowFullScreen=""
              loading="lazy"
              title="maps"
            ></iframe>
          </div>
        </div>
        <div>
          <p className="footer-text">Llámanos</p>
          <div className="footer-icons-grid">
            <a
              href="https://wa.me/527717009271"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                placeholder="blurred"
                src="../images/whatsapp.png"
                className="footer-icon"
                layout="fullWidth"
                alt=""
              />
            </a>
            <a href="tel:771-281-3890">
              <StaticImage
                placeholder="blurred"
                src="../images/telefon.png"
                className="footer-icon"
                layout="fullWidth"
                alt=""
              />
            </a>
            <a href="mailto:admisiones@cenhies.edu.mx">
              <StaticImage
                placeholder="blurred"
                src="../images/email.png"
                className="footer-icon"
                layout="fullWidth"
                alt=""
              />
            </a>
          </div>
        </div>
        <div>
          <p className="footer-text">Síguenos</p>
          <div className="footer-two-icons-grid">
            <a
              href="https://www.facebook.com/cenhiesoficial/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                placeholder="blurred"
                src="../images/facebook.png"
                className="footer-icon"
                layout="fullWidth"
                alt=""
              />
            </a>
            <a
              href="https://www.instagram.com/cenhies_oficial/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                placeholder="blurred"
                src="../images/instagram.png"
                className="footer-icon"
                layout="fullWidth"
                alt=""
              />
            </a>
          </div>
          <div className="footer-icons-grid">
            <a
              href="https://www.youtube.com/channel/UC3ka7zeAw5IbGqhEBPVXiMA"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                placeholder="blurred"
                src="../images/youtub.png"
                className="footer-icon"
                layout="fullWidth"
                alt=""
              />
            </a>
            <a
              href="https://twitter.com/CENHIESred/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                placeholder="blurred"
                src="../images/twitte.png"
                className="footer-icon"
                layout="fullWidth"
                alt=""
              />
            </a>
            <a
              href="https://vm.tiktok.com/ZMLHvqNCQ/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                placeholder="blurred"
                src="../images/tiktok.png"
                className="footer-icon"
                layout="fullWidth"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-two-text-grid">
        <p className="footer-subtext">SOMOS LEONES</p>
      </div>
      <div className="footer-text-grid">
        <Link style={{ textDecoration: "none" }} to="/normatividad">
          <p className="footer-bottomtext">Normatividad</p>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/normatividad-secundaria">
          <p className="footer-bottomtext">Normatividad Secundaria</p>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/aviso-de-privacidad">
          <p className="footer-bottomtext">Aviso de Privacidad</p>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/terminos-y-condiciones">
          <p className="footer-bottomtext">Terminos y Condiciones</p>
        </Link>
      </div>
      <p className="footer-bottomtext">
        D.R. © {new Date().getFullYear()}, Centro Hidalguense de Estudios
        Superiores, S.C.
      </p>
    </div>
  </footer>
)

export default Footer
